<template>
    <div id="app">
        <router-view/>
    </div>
</template>



<script>
export default {

 data() {
        return {
          device_admin: null,
          path:"wss://yjyiot2.msqsoft.net:8234?code=",
          id:"",
          role_list: [],
          websock:null,
          ck:{
            swerr:"",
            swpush:"",
            data:"",
            id:"",
            data485:"",
            ckpush:{}, //每组的数值变化
          }
        }
      },


      created(){
        // getDemoValue
        // new.$on('getisshow',data =>{
        //   console.log(data)
        // })
       
    //  this.id = 
      },
      mounted () {
        // const data = JSON.parse(JSON.stringify(this.$store.getters));
        // console.log(typeof data.getDemojson)
        this.id = localStorage.getItem("demoVal")
        console.log(localStorage.getItem("demoVal"),'----');
        setTimeout(()=>{
        this.initWebSocket()
        },1500)
        
      },

  methods: {
      initWebSocket() { //初始化weosocket             
        this.websock = new WebSocket(this.path+this.id);
        // 监听websock消息
        this.websock.onmessage = this.websocketonmessage;
        // 监听websock连接
        this.websock.onopen = this.websocketonopen;
         // 监听websock错误信息
        this.websock.onerror = this.websocketonerror;
        // 监听websock关闭连接
        this.websock.onclose = this.websocketclose;
  
      },
      websocketonopen(e) { //连接建立
  
    //   this.websocketsend("fbfc0102030405060708090a0b0c00");
      // }, 200);
        console.log('连接成功', e)
        // this.isLoading = false
      },
      websocketonerror(e) {//连接建立失败重连   
        console.log('连接错误', e)
        console.log(this.websock.readyState)
        var that = this
        setTimeout(function () {
          if ( that.websock.readyState !== 1 && that.websock.readyState !== 0) {
            that.websock = null
            that.initWebSocket();
          }
        }, 1000)
  
      },
      websocketonmessage(e) { //数据接收   

        // console.log('数据接收',e.data )
        this.$store.commit('setDemojson', e.data);  //上传
        // console.log(this.$store.getters.getDemojson,'--------')
  
      },
      websocketsend(Data) 
      {//数据发送         
        this.websock.send(Data);
      },
      websocketclose(e) {  //关闭  
        var that = this
        // 断线重连
        console.log(this.websock.readyState)
  
        setTimeout(function () {
          if (that.websock.readyState !== 1 && that.websock.readyState !== 0) {
            that.websock = null
            that.initWebSocket();
          }
        }, 1000)
        console.log('断开连接', e);
      },
  }

}
</script>



<style lang="scss" scoped>
#app {
    width: 100%;
    height: 100%;
}
</style>
