// mutations-types
const ALL_DATA = 'ALL_DATA'
const STATUS_DATA = 'STATUS_DATA'
const ERR485_DATA = 'ERR485_DATA'
const ERR_DATA = 'ERR_DATA'
const LILE_DATA = 'LILE_DATA'
const MODE_DATA = 'MODE_DATA'
const SHEZHI_DATA = 'SHEZHI_DATA' 
const GUANYU_DATA = 'GUANYU_DATA'
export default {
  namespaced: true,
  state: {
    data: {},
    modeStatus: '手动',
    modezi:'自动',
    modeData: true,
    lifeData: true,
    errData: true,
    err485Data: true,
    shezhiData: true,
    guanData: true,
  },
  mutations: {
    [ALL_DATA](state, data) {
      state.data = data
    },
    [STATUS_DATA](state, data) {
      state.modeStatus = data
      state.modezi = data
    },
    [ERR485_DATA](state, data) {
      state.err485Data = data
    },
    [ERR_DATA](state, data) {
      state.errData = data
    },
    [LILE_DATA](state, data) {
      state.lifeData = data
    },
    [MODE_DATA](state, data) {
      state.modeData = data
    },
    [SHEZHI_DATA](state, data) {
      state.shezhiData = data
    },
    [GUANYU_DATA](state, data) {
      state.guanData = data
    }
  },
  actions: {
    set_data({ commit }, data) {
      const currentData = data.data
      commit(ALL_DATA, currentData)
      commit(STATUS_DATA, currentData['manual.t0.txt'])
      commit(ERR485_DATA, currentData.hasOwnProperty('err485.p0.pic') ? true : false)
      commit(ERR_DATA, currentData.hasOwnProperty('err.p0.pic') ? true : false)
      commit(MODE_DATA, currentData.hasOwnProperty('manual.bt0.val') ? true : false)
      commit(LILE_DATA, currentData.hasOwnProperty('life.n0.val') ? true : false)
      // commit(GUANYU_DATA, currentData.hasOwnProperty('time.b0.txt') ? currentData['time.b0.txt'] : false)

    }
  },
  getters: {
    data(state) {
      return state.data
    },
    modeStatus(state) {
      return state.modeStatus
    },
    modezi(state) {
      return state.modezi
    },
    modeData(state) {
      return state.modeData
    },
    errData(state) {
      return state.errData
    },
    err485Data(state) {
      return state.err485Data
    },
    lifeData(state) {
      return state.lifeData
    },
    shezhiData(state){
      return state.shezhiData
    },
    guanData(state){
      return state.guanData
    }

  }
}
